import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd69ee04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-section" }
const _hoisted_2 = { class: "form-section bordered" }
const _hoisted_3 = { class: "b-form-input" }
const _hoisted_4 = { class: "space-around-vertically" }
const _hoisted_5 = { class: "space-around-vertically" }
const _hoisted_6 = {
  key: 0,
  class: "space-around-vertically"
}
const _hoisted_7 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_8 = { class: "centered" }
const _hoisted_9 = { class: "form-section bordered" }
const _hoisted_10 = { class: "b-form-input" }
const _hoisted_11 = { class: "centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_safety_inspection_questionnaire, {
      modelValue: _ctx.state.showEquipmentInspection,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showEquipmentInspection) = $event)),
      "inspection-type": _ctx.SafetyInspectionType.EquipmentInspection,
      "inspection-title": _ctx.getTitleCaseTranslation('core.common.equipmentSafetyInspection'),
      onCanceled: _ctx.cancelEquipmentInspection,
      onRejected: _ctx.cancelEquipmentInspection
    }, null, 8, ["modelValue", "inspection-type", "inspection-title", "onCanceled", "onRejected"]),
    _createVNode(_component_screen, {
      title: _ctx.getTitleCaseTranslation('core.domain.processedPutAway'),
      heading: _ctx.getTitleCaseTranslation('core.domain.processedPutAway'),
      full: "",
      padded: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_smart_trak_footer, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_button, {
              class: "submit-button",
              disabled: _ctx.state.disabledForError || _ctx.isSubmitButtonDisabled || _ctx.state.submitting,
              variant: "primary",
              onClick: _ctx.submitProcessPutAway
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')) + " ", 1),
                (_ctx.state.submitting)
                  ? (_openBlock(), _createBlock(_component_b_spinner, {
                      key: 0,
                      small: ""
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, {
              cols: "4",
              "cols-offset": "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.item')), 1),
                  _createVNode(_component_item_picker, {
                    key: _ctx.state.refreshItemPickerKey,
                    "item-type": _ctx.ItemType.PUT_AWAY,
                    "search-only": "",
                    placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
                    onOnClear: _ctx.clearItem,
                    onOnSelect: _ctx.selectItem
                  }, null, 8, ["item-type", "placeholder", "onOnClear", "onOnSelect"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.fromLocation')) + ": " + _toDisplayString(_ctx.state.pullFromLocation.name), 1),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.putAwayLocation')) + ": " + _toDisplayString(_ctx.state.putAwayLocation?.name), 1),
                    (_ctx.state.item && _ctx.state.item.floorLocation)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.floorLocation')) + ": " + _toDisplayString(_ctx.state.item?.floorLocation), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.state.putAwayPayload.quantity,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.putAwayPayload.quantity) = $event)),
                    disabled: _ctx.state.disabledForError,
                    label: _ctx.getTitleCaseTranslation('core.domain.quantity'),
                    "number-options": { allowsNegative: true },
                    type: "number"
                  }, null, 8, ["modelValue", "disabled", "label"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_thumbnail, {
                      "image-url-thumb": _ctx.state.item?.imageUrlThumb,
                      "image-url-full": _ctx.state.item?.imageUrlFull,
                      size: "lg"
                    }, null, 8, ["image-url-thumb", "image-url-full"])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { cols: "4" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.to')), 1),
                    _createVNode(_component_b_form_select, {
                      modelValue: _ctx.state.putAwayPayload.toInventoryCategoryId,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.putAwayPayload.toInventoryCategoryId) = $event)),
                      disabled: _ctx.state.disabledForError || _ctx.state.toInvCatDisabled,
                      options: _ctx.toInvCatOptions,
                      "text-field": "text",
                      "value-field": "value"
                    }, null, 8, ["modelValue", "disabled", "options"])
                  ]),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.onHandInventory')) + ": " + _toDisplayString(_ctx.selectedInvCatOnHandQty), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title", "heading"])
  ], 64))
}