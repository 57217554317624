
import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    reactive,
    watch,
} from 'vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import Screen from '@/components/layout/Screen.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { useNotification } from '@/composable/useNotifications';
import ItemType from '@/domain/enums/ItemType';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import Item from '@/domain/Item';
import Location from '@/domain/Location';
import ProcessPutAwayDTO from '@/dtos/ProcessPutAwayDTO';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import router from '@/router';
import InventoryService from '@/services/InventoryService';
import LocationService from '@/services/LocationService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import CoreStore from '@/store/CoreStore';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';

type State = {
    disabledForError: boolean;
    item?: Item;
    itemOnHandGroupedInventory?: InventoryGroupedDTO;
    pullFromLocation: Location;
    putAwayLocation?: Location;
    putAwayPayload: ProcessPutAwayDTO;
    refreshItemPickerKey: number;
    showEquipmentInspection: boolean;
    submitting: boolean;
    toInvCatDisabled: boolean;
};

export default defineComponent({
    name: 'processed-put-away',
    components: {
        BButton,
        BCol,
        BFormInput,
        BFormSelect,
        BRow,
        BSpinner,
        ItemPicker,
        SafetyInspectionQuestionnaire,
        Screen,
        SmartTrakFooter,
        Thumbnail,
    },
    setup() {
        const { inventoryCategoryStore } = CoreStore.getInstance();
        const inventoryService = new InventoryService();
        const locationService = new LocationService();
        const { showError, showSuccess } = useNotification();
        const { userLocation, forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            disabledForError: false,
            pullFromLocation: new Location(),
            putAwayPayload: new ProcessPutAwayDTO({
                fromLocationId: userLocation.processedPutAwayPullFromLocationId,
            }),
            refreshItemPickerKey: 0,
            showEquipmentInspection: !!userLocation.processedPutAwayPullFromLocationId,
            submitting: false,
            toInvCatDisabled: false,
        });

        const isSubmitButtonDisabled = computed(() => (
            !(state.item && state.putAwayPayload.quantity && state.putAwayPayload.toInventoryCategoryId)
        ));
        const selectedInvCatOnHandQty = computed(() => {
            if (state.putAwayPayload.toInventoryCategoryId) {
                return state.itemOnHandGroupedInventory?.getInventoryForCategoryId(state.putAwayPayload.toInventoryCategoryId) ?? undefined;
            }

            return undefined;
        });
        const toInvCatOptions = computed(() => inventoryCategoryStore.canMoveInventoryCategories.map((cat) => ({
            text: cat.description,
            value: cat.id,
        })));

        onBeforeMount(async () => {
            if (userLocation.processedPutAwayPullFromLocationId) {
                const pullFromLocation = await locationService.getLocationById(userLocation.processedPutAwayPullFromLocationId);

                if (pullFromLocation) {
                    state.pullFromLocation = pullFromLocation;
                }
            } else {
                showError(getTranslation('core.validation.yourLocationMustHaveAProcessedPutAwayPullFromLocation'));
                state.disabledForError = true;
            }
        });
        
        onMounted(async () => {
            if (!forkliftCertified) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationMissing'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            else if(!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationExpired'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }
        });
        
        watch(() => state.item, (newItem?: Item) => {
            if (newItem?.isUnitLoad) {
                state.toInvCatDisabled = true;
                state.putAwayPayload.toInventoryCategoryId = inventoryCategoryStore.getFinishedGoodsCategory()?.id;
            } else {
                state.toInvCatDisabled = false;
            }
        });

        function cancelEquipmentInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function clearItem() {
            state.refreshItemPickerKey += 1;
            state.item = undefined;
            state.itemOnHandGroupedInventory = undefined;
            state.putAwayLocation = undefined;
            state.putAwayPayload.itemId = 0;
        }

        async function selectItem(selectedItem: { item: Item }) {
            state.item = selectedItem.item;

            if (state.item) {
                const putAwayLocation = state.item.type.overridePutAwayLocation ? state.item.type.overridePutAwayLocation : userLocation;

                state.putAwayLocation = putAwayLocation;
                state.putAwayPayload.itemId = state.item.id;
                state.putAwayPayload.toLocationId = putAwayLocation.id;
                state.itemOnHandGroupedInventory = await inventoryService
                    .getItemInventoryGroupedByCategoryForSingleItem(state.item.id, state.putAwayLocation.id);
            }
        }

        async function submitProcessPutAway() {
            state.submitting = true;

            const acceptedQty = await inventoryService.processPutAway(state.putAwayPayload);

            if (acceptedQty) {
                showSuccess(getTranslation('core.common.itemPutAwaySuccessfully'));
                clearItem();
                state.putAwayPayload = new ProcessPutAwayDTO({
                    fromLocationId: userLocation.processedPutAwayPullFromLocationId,
                });
            }

            state.submitting = false;
        }

        return {
            cancelEquipmentInspection,
            clearItem,
            getTranslation,
            getTitleCaseTranslation,
            isSubmitButtonDisabled,
            ItemType,
            SafetyInspectionType,
            selectedInvCatOnHandQty,
            selectItem,
            state,
            submitProcessPutAway,
            toInvCatOptions,
        };
    },
});
