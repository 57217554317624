export default class ProcessPutAwayDTO {
    public fromLocationId: number = 0;

    public toLocationId: number = 0;

    public itemId: number = 0;

    public toInventoryCategoryId?: number;

    public quantity: number = 0;

    constructor(init?: Partial<ProcessPutAwayDTO>) {
        Object.assign(this, init);
    }
}
